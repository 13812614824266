import generateRandomString from "./GenerateRandomString"

function transformSchema(schema) {
    // Transform QnA schema
    let updatedSchema = {...schema}
    for (const tableName in schema["QnA"]){
        const fields = []
        const impFields = new Set()
        for (const fieldName of schema["QnA"][tableName]["ImportantFields"]){
            impFields.add(fieldName)
        }
        for (const fieldName in schema["QnA"][tableName]["Rows"]){
            fields.push({
                "FieldKeyName": fieldName,
                "IsImportant": impFields.has(fieldName),
                "uuid": generateRandomString(),
                ...schema["QnA"][tableName]["Rows"][fieldName]
            })
        }
        updatedSchema["QnA"][tableName]["Rows"] = fields
        delete updatedSchema["QnA"][tableName]["ImportantFields"]

        const join_conditions = []
        for (const join_condition_table in schema["QnA"][tableName]["join_conditions"]){
            join_conditions.push({
                "uuid": generateRandomString(),
                "TableName": join_condition_table,
                "Conditions": schema["QnA"][tableName]["join_conditions"][join_condition_table]
            })
        }
        updatedSchema["QnA"][tableName]["join_conditions"] = join_conditions
    }
    schema = updatedSchema
    const tables = []
    for (const tableName in updatedSchema["QnA"]){
        tables.push({
            "TableKeyName": tableName,
            "uuid": generateRandomString(),
            ...updatedSchema["QnA"][tableName]
        })
    }
    updatedSchema["QnA"] = tables

    // Transform Summary schema
    const to_clean_data = []
    for (const data_to_clean_label in schema['Summary']?.['data_to_clean']?.['data']) {
        to_clean_data.push({
            "label": data_to_clean_label,
            "data": schema['Summary']['data_to_clean']['data'][data_to_clean_label]
        })
    }
    updatedSchema['Summary']['data_to_clean']['data'] = to_clean_data
    const to_save_data = []
    for (const data_to_save_label in schema['Summary']?.['data_to_save']?.['data']) {
        to_save_data.push({
            "label": data_to_save_label,
            "data": schema['Summary']['data_to_save']['data'][data_to_save_label]
        })
    }
    updatedSchema['Summary']['data_to_save']['data'] = to_save_data
    return updatedSchema
}

function divideSchema(schema) {
    schema = transformSchema(schema)
    return {
        qna: {"QnA": schema["QnA"]},
        summary: {"Summary": schema["Summary"]}
    }
}

function validateQnaSchema(qnaSchema){
    const tableKeyNames = new Set()
    const tableNames = new Set()
    // check if any table name has not been repeated
    for (let i = 0; i < qnaSchema["QnA"].length; i++){
        if (tableKeyNames.has(qnaSchema["QnA"][i]["TableKeyName"]))
            throw new Error(`Invalid QnA Schema. Table Display name ${qnaSchema["QnA"][i]["TableKeyName"]} has been repeated`)
        if (tableNames.has(qnaSchema["QnA"][i]["TableName"]))
            throw new Error(`Invalid QnA Schema. Table name ${qnaSchema["QnA"][i]["TableName"]} has been repeated`)
        tableKeyNames.add(qnaSchema["QnA"][i]["TableKeyName"])
        tableNames.add(qnaSchema["QnA"][i]["TableName"])

        if (qnaSchema["QnA"][i]["Rows"].length === 0)
            throw new Error(`Invalid QnA Schema. Table ${qnaSchema["QnA"][i]["TableKeyName"]} has no Rows`)
        // check if any field name has not been repeated
        const fieldKeyNames = new Set()
        for (let j = 0; j < qnaSchema["QnA"][i]["Rows"].length; j++){
            if (fieldKeyNames.has(qnaSchema["QnA"][i]["Rows"][j]["FieldKeyName"]))
                throw new Error(`Invalid QnA Schema. Field name ${qnaSchema["QnA"][i]["Rows"][j]["FieldKeyName"]} has been repeated in table ${qnaSchema["QnA"][i]["TableKeyName"]}`)
            fieldKeyNames.add(qnaSchema["QnA"][i]["Rows"][j]["FieldKeyName"])
        }
        // check if any join condition table name has not been repeated
        const joinConditionTableNames = new Set()
        for (let j = 0; j < qnaSchema["QnA"][i]["join_conditions"].length; j++){
            if (joinConditionTableNames.has(qnaSchema["QnA"][i]["join_conditions"][j]["TableName"]))
                throw new Error(`Invalid QnA Schema. Join condition table name ${qnaSchema["QnA"][i]["join_conditions"][j]["TableName"]} has been repeated in table ${qnaSchema["QnA"][i]["TableKeyName"]}`)
            joinConditionTableNames.add(qnaSchema["QnA"][i]["join_conditions"][j]["TableName"])
        }
    }
}
function mergeSchema(qnaSchema, summarySchema){
    validateQnaSchema(qnaSchema)
    // Transform QnA schema
    let updatedQnaSchema = JSON.parse(JSON.stringify(qnaSchema)) // Deep copy
    let tables = {}
    for(let i = 0; i < updatedQnaSchema["QnA"].length; i++){
        // transform rows
        let updatedRows = {}
        updatedQnaSchema["QnA"][i]["ImportantFields"] = []
        for (const row of updatedQnaSchema["QnA"][i]["Rows"]){
            updatedRows[row["FieldKeyName"]] = row
            if (row["IsImportant"])
                updatedQnaSchema["QnA"][i]["ImportantFields"].push(row["FieldKeyName"])
            delete updatedRows[row["FieldKeyName"]]["IsImportant"]
            delete updatedRows[row["FieldKeyName"]]["isNewRow"]
            delete updatedRows[row["FieldKeyName"]]["uuid"]
            delete updatedRows[row["FieldKeyName"]]["FieldKeyName"]
        }
        updatedQnaSchema["QnA"][i]["Rows"] = updatedRows
        // transform join_conditions
        let join_conditions = {}
        for (const join_condition of updatedQnaSchema["QnA"][i]["join_conditions"]){
            join_conditions[join_condition["TableName"]] = join_condition["Conditions"]
            delete join_conditions[join_condition["TableName"]]["uuid"]
            delete join_conditions[join_condition["TableName"]]["TableName"]
        }
        updatedQnaSchema["QnA"][i]["join_conditions"] = join_conditions
        // transform QnA
        tables[updatedQnaSchema["QnA"][i]["TableKeyName"]] = updatedQnaSchema["QnA"][i]
        delete tables[updatedQnaSchema["QnA"][i]["TableKeyName"]]["isNewTable"]
        delete tables[updatedQnaSchema["QnA"][i]["TableKeyName"]]["uuid"]
        delete tables[updatedQnaSchema["QnA"][i]["TableKeyName"]]["TableKeyName"]
    }
    updatedQnaSchema["QnA"] = tables

    // Transform Summary schema
    let updatedSummarySchema = JSON.parse(JSON.stringify(summarySchema)) // Deep copy
    let to_clean_data = {}
    for (const data of updatedSummarySchema['Summary']['data_to_clean']['data']) 
        to_clean_data[data['label']] = data['data']
    updatedSummarySchema['Summary']['data_to_clean']['data'] = to_clean_data

    let to_save_data = {}
    for (const data of updatedSummarySchema['Summary']['data_to_save']['data'])
        to_save_data[data['label']] = data['data']
    updatedSummarySchema['Summary']['data_to_save']['data'] = to_save_data

    return {
        ...updatedQnaSchema,
        ...updatedSummarySchema
    }
}

export { divideSchema, mergeSchema }