import React, {useState, useEffect} from "react"
import createUserSchema from '../jsonFormSchema/createUser/createUserSchema.json';
import createUserUISchema from '../jsonFormSchema/createUser/createUserUISchema.json';
import { JsonForms } from '@jsonforms/react';
import "../style.css";
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useCustomStorage } from '../hooks/useCustomStorage';
import { USER_API_URL } from "../constants/urls"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

const defaultUser = {
    username: "",
    isAdmin: false,
    tenants: []
}
function CreateUserModal({closeCreateUserModalOpen, addUserToAdminPanel}){
    const [authKey, setAuthKey] = useState("")
    const [userData, setUserData] = useState(defaultUser)
    const [userErrors, setUserErrors] = useState({})
    const [authToken, setAuthToken] = useCustomStorage("pivotal_auth_token")
    const [isLoading, setIsLoading] = useState(false) 
    const handleCreateUser = () => {
        setIsLoading(true)
        fetch(`${USER_API_URL}?product=Pivotal`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "x-api-key": authToken
            },
            body: JSON.stringify({
                ...userData,
                tenants: userData.tenants.map(tenant => {return({id: tenant, product: "Pivotal"})})
            })
        })
        .then((response) => {
            if(response.status !== 200){
            throw new Error("Something went wrong")
            }
            return response.json()
        })
        .then((response) => {
            setIsLoading(false)
            setAuthKey(response.auth_key)
            addUserToAdminPanel(
                {
                    ...response,
                    tenants: response.tenants.map(tenant => tenant['id'])
                }
            )
            toast.success(`Successfully created user ${userData.username}`)
        })
        .catch((err) => {
            console.log(err)
            toast.error("Something Went wrong")
            setIsLoading(false)
        })
    }
    return(
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {
                    isLoading ? (
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{width: "100%"}}>
                            <div style={{position: "absolute", right: "20px", top: "20px", cursor: "pointer"}} onClick={() => closeCreateUserModalOpen()}><CloseIcon /></div>
                        {
                            authKey ? (
                                <div>
                                    <p>User "{userData.username}" has been created successfully. Store the following authentication key securely:</p>
                                    <strong style={{marginTop: "10px"}}>{authKey}</strong>
                                    <p>You will not be able to see this authentication key again.</p>
                                </div>
                            ) : (
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: "10px"}}>
                                        Create User
                                    </Typography>
                                    <JsonForms
                                        schema={createUserSchema}
                                        uischema={createUserUISchema}
                                        data={userData}
                                        renderers={materialRenderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) =>{
                                            setUserData(data)
                                            setUserErrors(errors)
                                        }}
                                    />
                                    <button
                                        style={{
                                            margin: "2rem 0 2rem 0",
                                            fontSize: "1rem",
                                            backgroundColor: "#0083ce"
                                        }}
                                        onClick={() => {
                                            handleCreateUser()
                                        }}
                                    >
                                        Create User
                                    </button>
                                </div>
                            )
                        }
                        </div>
                    )
                }
            </div>
    )
}

export default CreateUserModal;