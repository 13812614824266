import React, { useState, useEffect } from 'react';
import { TENANT_CONFIG_URL } from '../constants/urls';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';

import "../style.css";
function Header({isAdmin, isAuthorized, tenantId, setTenantId}){
    const [tenantList, setTenantList] = useState([])
    useEffect(() => {
        if(isAuthorized) {
            fetch(`${TENANT_CONFIG_URL}?product=Pivotal`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": JSON.parse(localStorage.getItem("pivotal_auth_token"))
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json();
                else
                    throw new Error("response status", response.status)
            }).then((response) => {
                console.log("tenant response", response)
                setTenantList(response.tenants)
                setTenantId(response.tenants[0])
            }).catch((error) => {
                toast.error("Something went wrong while fetching available tenants")
                console.log(error)
            })
        }
    }, [isAuthorized])
    
    const handlePageChange = () => {
        if(window.location.pathname === "/")
            window.location.pathname = "/admin"
        else
            window.location.pathname = "/"
    }
    return (
        <header className='pivotal-header'>
            {
                isAdmin && (
                    <button 
                        onClick={handlePageChange}
                        style={{
                            fontSize: "1rem",
                            backgroundColor: "#0083ce",
                            position: "absolute",
                            left: "8%"
                        }}
                    >
                        {
                            window.location.pathname === "/" ? (
                                <>Go to Admin page</>
                            ) : (
                                <>Go to Home page</>
                            )
                        }
                    </button>
                )
            }
            {
                window.location.pathname !== "/admin" ? (
                    <div>Pivotal Admin Portal</div>
                ) : (
                    <div>Pivotal Global Admin Portal</div>
                )
            }
            {
                isAuthorized && window.location.pathname === "/" && (
                    <FormControl 
                        sx={{ m: 1, minWidth: 120 }} 
                        size="small" 
                        style={{
                            position: "absolute",
                            right: "8%"
                        }}
                    >
                        <InputLabel id="demo-select-small-label">Tenant ID</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={tenantId}
                            label="TenantID"
                            onChange={(e) => setTenantId(e.target.value)}
                            size="small"
                        >
                            {
                                tenantList.map((tenant) => {
                                    return <MenuItem value={tenant} key={tenant}>{tenant}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                )
            }
        </header>
    )
}
export default Header;