import react from 'react';
import { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import joinConditionsSchema from '../jsonFormSchema/qna/joinConditions/joinConditionsSchema.json';
import "../style.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import generateRandomString from '../utils/GenerateRandomString';

function JoinConditions({qna, tableIndex, qnaData, setQnaData}){
    const [openDeleteJoinPopup, setOpenDeleteJoinPopup] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const handleAddJoinCondition = () => {
        setQnaData({
            "QnA": [
                ...qnaData["QnA"].slice(0, tableIndex),
                {
                    ...qnaData["QnA"][tableIndex],
                    "join_conditions": [
                        ...qnaData["QnA"][tableIndex]["join_conditions"],
                        {
                            "uuid": generateRandomString(),
                            "isNewJoin": true,
                            "TableName": "",
                            "Conditions": []
                        }
                    ]
                },
                ...qnaData["QnA"].slice(tableIndex + 1)
            ]
        })
    }
    const handleDeleteJoinCondition = (index) => {
        setQnaData(
            {
                "QnA": [
                    ...qnaData["QnA"].slice(0, tableIndex),
                    {
                        ...qnaData["QnA"][tableIndex],
                        "join_conditions": [
                            ...qnaData["QnA"][tableIndex]["join_conditions"].slice(0, index),
                            ...qnaData["QnA"][tableIndex]["join_conditions"].slice(index + 1)
                        ]
                    },
                    ...qnaData["QnA"].slice(tableIndex + 1)
                ]
            }
        )
        setDeleteIndex(-1)
    }
    return(
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            {
                openDeleteJoinPopup && (
                    <Dialog
                        open={openDeleteJoinPopup}
                        onClose={() => setOpenDeleteJoinPopup(false)}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Delete Join Conditions with Table "{qna?.["join_conditions"][deleteIndex]["TableName"]}"?
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete all join conditions with the table "{qna?.["join_conditions"][deleteIndex]["TableName"]}"?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button 
                            onClick={() =>{
                                setOpenDeleteJoinPopup(false)
                                handleDeleteJoinCondition(deleteIndex)
                            }}
                        >
                            Yes
                        </Button>
                        <Button onClick={() => setOpenDeleteJoinPopup(false)} autoFocus>
                            No
                        </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                qna?.["join_conditions"]?.map((join, index) => {
                    return(
                        <div key={join?.['uuid']} style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "90%"}}>
                            <Accordion 
                                TransitionProps={{ unmountOnExit: !join?.['isNewJoin'] }} 
                                style={{
                                    padding: "0 1rem",
                                    width: "89%",
                                    paddingBottom: "0",
                                    backgroundColor: "#FAF9F6",
                                    marginBottom: "0.5rem"
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{height: "50px"}}
                                >
                                <Typography>{join?.['TableName']}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{paddingBottom: "2rem"}}
                                >
                                    <JsonForms
                                        schema={joinConditionsSchema}
                                        // uischema={} 
                                        data={join}
                                        renderers={materialRenderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) =>{
                                            setQnaData(
                                                {
                                                    "QnA": [
                                                        ...qnaData["QnA"].slice(0, tableIndex),
                                                        {
                                                            ...qnaData["QnA"][tableIndex],
                                                            "join_conditions": [
                                                                ...qnaData["QnA"][tableIndex]["join_conditions"].slice(0, index),
                                                                data,
                                                                ...qnaData["QnA"][tableIndex]["join_conditions"].slice(index + 1)
                                                            ]
                                                        },
                                                        ...qnaData["QnA"].slice(tableIndex + 1)
                                                    ]
                                                }
                                            )
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <button
                                style={{
                                    outline: "none",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "0.25rem",
                                    cursor: "pointer",
                                    width: "6%",
                                    backgroundColor: "#f44336",
                                    height: "50px",
                                    marginTop: "0"
                                }}
                                onClick={() => {
                                    setDeleteIndex(index)
                                    setOpenDeleteJoinPopup(true)
                                }}
                            >
                                <DeleteOutlinedIcon />
                            </button>
                        </div>
                    )
                })
            }
            <button
                style={{
                    padding: "1rem",
                    outline: "none",
                    color: "white",
                    border: "none",
                    borderRadius: "0.25rem",
                    cursor: "pointer",
                    marginTop: "1rem",
                    width: "90%",
                    fontSize: "1rem",
                    backgroundColor: "#4CAF50"
                }}
                onClick={handleAddJoinCondition}
            >
                Add Join Condition
            </button>
        </div>
    )
}
export default JoinConditions;