import logo from './logo.svg';
import './App.css';
import AuthTokenInput from './components/Auth';
import { useEffect, useState } from "react"
import Header from './components/Header';
import SchemaModifer from './components/SchemaModifier';
import Admin from './components/Admin';
import { ToastContainer, toast } from 'react-toastify';
import { USER_PROFILE_API_URL } from './constants/urls';
import 'react-toastify/dist/ReactToastify.min.css'
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useCustomStorage } from './hooks/useCustomStorage';
import { CircularProgress } from '@mui/material';

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [authToken, setAuthToken] = useCustomStorage("pivotal_auth_token")
  const [tenantId, setTenantId] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (window.location.pathname === "/auth") return
    if (!authToken){
      window.location.href = "/auth"
      return
    }
    setIsLoading(true)
    fetch(`${USER_PROFILE_API_URL}?product=Pivotal`, {
      method: "GET",
      headers: {
        "x-api-key": authToken
      }
    })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setIsAuthorized(true)
            return response.json()
          } 
          else {
            window.location.href = "/auth"
            setIsAuthorized(false)
          }
        }
      })
      .then((response) => {
        setIsAdmin(response.isAdmin)
      })
      .catch((error) => {
        window.location.href = "/auth"
        console.error(error)
        setIsAuthorized(false)
      }).finally(() => {
        setIsLoading(false)
      })
  }, [])
  return (
    <div>
      {
        isLoading ? (
          <div
              style={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
            >
              <CircularProgress />
              <div style={{marginTop: "20px"}}>
                Fetching Authorization Status...
              </div>
            </div>
        ) : (
          <Router>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
              <Header isAdmin={isAdmin} isAuthorized={isAuthorized} tenantId={tenantId} setTenantId={setTenantId} />
              <ToastContainer />
              <Routes>
                <Route path="/"
                  element={isAuthorized ? <SchemaModifer tenantId={tenantId} />: <></>}
                />
                <Route path="/auth"
                  element={<AuthTokenInput isAuthorized={isAuthorized} setIsAuthorized={setIsAuthorized} />}
                />
                {
                  isAdmin && (
                    <Route path="/admin"
                      element={<Admin />}
                    />
                  )
                }
              </Routes>
            </div>
          </Router>
        )
      }
    </div>
  );
}

export default App;
