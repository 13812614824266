import React, { useEffect, useState } from 'react';
import "../style.css";
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { USER_API_URL } from "../constants/urls"
import { JsonForms } from '@jsonforms/react';
import createUserSchema from '../jsonFormSchema/createUser/createUserSchema.json';
import updateUserUISchema from '../jsonFormSchema/createUser/updateUserUISchema.json';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import generateRandomString from '../utils/GenerateRandomString';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useCustomStorage } from '../hooks/useCustomStorage';
import CreateUserModal from "./CreateUserModal"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null
};

function UserTab(){
    const [userData, setUserData] = useState([])
    const [errorData, setErrorData] = useState({})
    const [openUpdatePopup, setOpenUpdatePopup] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const [updateIndex, setUpdateIndex] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const [authToken, setAuthToken] = useCustomStorage("pivotal_auth_token")
    const [createUserModalOpen, setCreateUserModalOpen] = useState(false)
    useEffect(() => {
      // Do the GET API call
      setIsLoading(true)
      fetch(`${USER_API_URL}?product=Pivotal`, {
        method: "GET",
        headers: {
          "x-api-key": authToken
        }
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Something went wrong")
        }
        return response.json()
      })
      .then((response)=>{
        // go through the tenants and set their values 
        console.log("user request res", response)
        const users = []
        for (const user of response){
          users.push({...user, "tenants": user.tenants.map(tenant => tenant['id'])})
        }
        console.log("users", users)
        setUserData(users)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        toast.error("Something Went Wrong")
        setIsLoading(false)
      })
    }, [])
    useEffect(() => {
      console.log("userData", userData)
      console.log("errorData", errorData)
    }, [userData, errorData])


    const handleUpdateUserData = (data) => {
      // setHasChanges(true)
      setUserData(data)
    }
    const handleDeleteUser = (index) => {
      setIsLoading(true)
        fetch(`${USER_API_URL}?product=Pivotal`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                "x-api-key": authToken
            },
            body: JSON.stringify({username: userData[deleteIndex].username})
        })
        .then((response) => {
            if(response.status !== 200){
            throw new Error("Something went wrong")
            }
            return response.json()
        })
        .then((response) => {
            console.log("delete user response", response)
            setIsLoading(false)
            handleUpdateUserData(userData.filter((user, i) => i !== index))
            toast.success(`User Deleted successfully!`)
        })
        .catch((err) => {
            console.log(err)
            toast.error("Something Went wrong")
            setIsLoading(false)
        })
    }
    const handleUpdate = () => {
      const requestBody = JSON.stringify({
        ...userData[updateIndex],
        tenants: userData[updateIndex].tenants.map(tenant => {return({id: tenant, product: "Pivotal"})})
      })
      setIsLoading(true)
      fetch(`${USER_API_URL}?product=Pivotal`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          "x-api-key": authToken
        },
        body: requestBody
      })
      .then((response) => {
        if(response.status !== 200){
          throw new Error("Something went wrong")
        }
        return response.json()
      })
      .then((response) => {
        setIsLoading(false)
        toast.success(`Successfully updated user ${userData[updateIndex].username}`)
        setUpdateIndex(-1)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Something Went wrong")
        setIsLoading(false)
      })
    }
    return (
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        {
          isLoading ? (
            <div style={{display: "flex", alignItems: "center", height: "80vh"}}>
                <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              {
                deleteIndex > -1 && (
                  <Dialog
                      open={openDeletePopup}
                      onClose={() => setOpenDeletePopup(false)}
                  >
                      <DialogTitle id="alert-dialog-title">
                          Delete User "{userData[deleteIndex]?.username}"?
                      </DialogTitle>
                      <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete the user "{userData[deleteIndex]?.username}"?
                      </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                      <Button 
                          onClick={() =>{
                              handleDeleteUser(deleteIndex)
                              setOpenDeletePopup(false)
                          }}
                      >
                          Yes
                      </Button>
                      <Button onClick={() => setOpenDeletePopup(false)} autoFocus>
                          No
                      </Button>
                      </DialogActions>
                  </Dialog>
                )
              }
              {
                userData.map((user, index) => {
                  return (
                    <div
                      key={user.uuid}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <Accordion 
                          style={{
                              padding: "0 1rem",
                              width: "90%",
                              paddingBottom: "0"
                          }}
                      >
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              style={{height: "50px"}}
                          >
                          <Typography sx={{fontWeight: "bold"}}>{user.username}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                              style={{paddingBottom: "2rem", width: "100%"}}
                          >
                            <div style={{display: "flex"}}> API Key: <Typography sx={{fontWeight: "bold", marginLeft: "5px"}}>{user.api_key}</Typography> </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                              <JsonForms
                                  schema={createUserSchema}
                                  uischema={updateUserUISchema}
                                  data={user}
                                  renderers={materialRenderers}
                                  cells={materialCells}
                                  onChange={({ data, errors }) =>{
                                    handleUpdateUserData([
                                      ...userData.slice(0, index),
                                      data,
                                      ...userData.slice(index + 1)
                                    ])
                                    setErrorData({
                                      ...errorData,
                                      [user.uuid]: errors
                                    })
                                  }}
                              />
                              <button
                                  style={{
                                      margin: "2rem 0 2rem 0",
                                      fontSize: "1rem",
                                      backgroundColor: "#0083ce"
                                  }}
                                  onClick={() => {
                                    setUpdateIndex(index)
                                    setOpenUpdatePopup(true)
                                  }}
                              >
                                  Update
                              </button>
                            </div>
                          </AccordionDetails>
                      </Accordion>
                      <button
                          style={{
                              width: "5%",
                              backgroundColor: "#f44336",
                              height: "50px",
                              padding: "0.2rem 0"
                          }}
                          onClick={() => {
                            setDeleteIndex(index)
                            setOpenDeletePopup(true)
                          }}
                      >
                          <DeleteOutlinedIcon />
                      </button>
                    </div>
                  )
                })
              }
              <button
                  style={{
                      marginTop: "1rem",
                      width: "100%",
                      fontSize: "1rem",
                      backgroundColor: "#4CAF50"
                  }}
                  onClick={() => setCreateUserModalOpen(true)}
              >
                  Add a new User
              </button>
              <Dialog
                  open={openUpdatePopup}
                  onClose={() => setOpenUpdatePopup(false)}
              >
                  <DialogTitle id="alert-dialog-title">
                      Are you sure you want to save changes made for {userData[updateIndex]?.username}?
                  </DialogTitle>
                  <DialogActions>
                  <Button 
                      onClick={() => {
                          handleUpdate()
                          setOpenUpdatePopup(false)
                      }}
                  >
                      Yes
                  </Button>
                  <Button onClick={() => setOpenUpdatePopup(false)} autoFocus>
                      No
                  </Button>
                  </DialogActions>
              </Dialog>
              <Modal
                open={createUserModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                  <Box sx={style}>
                    <CreateUserModal 
                      closeCreateUserModalOpen={() => setCreateUserModalOpen(false)}
                      addUserToAdminPanel={(newUserData) => {
                        console.log("new user data", newUserData)
                        setUserData([...userData, newUserData])}}  
                    />
                  </Box>
              </Modal>
            </div>
          )
        }
      </div>
    )
}
export default UserTab;